import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ErrorsGuard {
  constructor(private router: Router) {}
  canActivate() {
    const isFromRedirect =
      !!this.router.getCurrentNavigation()?.extras.state?.['isFromRedirect'];

    return isFromRedirect ? true : this.router.navigate(['/']);
  }
}
