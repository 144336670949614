import { ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ProductHeaderComponent } from './core/product-header/product-header.component';
import { MainNavComponent } from './core/main-nav/main-nav/main-nav.component';
import { PageComponent } from './core/page/page.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { MsalConfigModule } from './msal-config.module';
import { AllowedPermissionsDirective } from './shared/directives/allowed-permissions.directive';
import { MainNavItemComponent } from './core/main-nav/main-nav-item/main-nav-item.component';
import { CommonModule, DecimalPipe } from '@angular/common';
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { DATE_FORMATS, DATETIME_FORMATS } from './core/constants/global';
import {
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { DateFormatPipe } from './shared/pipes/date-format.pipe';
import { PreloaderComponent } from './shared/components/preloader/preloader.component';
import { PermissionsGuard } from './shared/guards/permissions.guard';
import { NavItemPermissionDirective } from './core/main-nav/nav-item-permission.directive';
import { MtxMomentDatetimeModule } from '@ng-matero/extensions-moment-adapter';
import { MTX_DATETIME_FORMATS } from '@ng-matero/extensions/core';
import { GlobalErrorHandler } from './handlers/global-error.handler';

@NgModule({
  declarations: [
    AppComponent,
    ProductHeaderComponent,
    MainNavComponent,
    PageComponent,
  ],
  bootstrap: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularSvgIconModule.forRoot(),
    MsalConfigModule,
    SharedModule,
    ReactiveFormsModule,
    AllowedPermissionsDirective,
    MatMomentDateModule,
    MtxMomentDatetimeModule,
    PreloaderComponent,
    MainNavItemComponent,
    NavItemPermissionDirective,
  ],
  providers: [
    PermissionsGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
    { provide: MTX_DATETIME_FORMATS, useValue: DATETIME_FORMATS },
    MomentDateAdapter,
    DateFormatPipe,
    DecimalPipe,
    provideHttpClient(withInterceptorsFromDi()),
  ],
})
export class AppModule {}
